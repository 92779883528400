import React, { useState, useEffect } from "react";

import {
  PDFViewer,
  Document,
  Page,
  Image,
  View,
  Text,
  Font,
  StyleSheet,
} from "@react-pdf/renderer";

import logo from "../../../logo.png";

import { useQuoteStore } from "./store";

import { Spinner } from "../../../common";

import { Table } from "./Table/Table";
import { Description } from "./Description";
import { Terms } from "./Terms";

import { quoteColumns, zoneTotalsColumns, additionalItemsColumns } from "./columns";
import { Heading, ClientInfo } from "./Heading";

export function DownloadPDF({ quote }) {
  // const { isLoading, quote } = useQuoteStore();

  return (
    <Document>
    <Page size="A4" style={styles.page}>
      <Heading quote={quote} />
      <Text style={styles.quoteText}>Quotation</Text>
      <ClientInfo quote={quote} />
      <Description description={quote.description} />
      <Text style={styles.heading}>Line Items</Text>
      <Table invoice={quote.quote_lines} columns={quoteColumns} groupable />

      {quote?.quote_addons.length ? (
        <View>
          <Text style={styles.heading}>Additional Items</Text>
          <Table
            type="Additional"
            invoice={quote.quote_addons}
            columns={additionalItemsColumns}
          />
        </View>
      ) : null}

      <Text style={styles.subTextBoldRed}>
        All prices exclude GST
      </Text>

      <Text style={{...styles.subTextMargin, marginTop: 5}}>
              •	All hireage and sales are subject to the Construction Contracts Act 2002.
            </Text>
            <Text style={{...styles.subTextMargin, marginTop: 5}}>
              •	Price assumes clear and unobstructed access to site, including parking for truck/s during erect and dismantle.
            </Text>
            <Text style={{...styles.subTextMargin, marginTop: 5}}>
              •	Equipment returned damaged or needing repair will be charged at full replacement cost.
            </Text>
            <Text style={{...styles.subTextMargin, marginTop: 5}}>
              •	Any additional work or alterations will be charged at $75+GST per man hour.
            </Text>
            <Text style={{...styles.subTextMargin, marginTop: 5}}>
              •	It is forbidden for the scaffold or edge protection to be altered by anyone other than an employee of ScaffM8 NZ Limited. If altered, variation charges will apply.
            </Text>
            <Text style={{...styles.subTextMargin, marginTop: 5}}>
              •	This quote is based on ScaffM8 NZ Limited being able to carry out their work uninterrupted or without delay. If interrupted or delayed, stand-down rates will apply.
            </Text>
            <Text style={{...styles.subTextMargin, marginTop: 5}}>
              • The Equipment is and will at all times remain the property of ScaffM8 NZ Limited.
            </Text>
    </Page>

    <Page size="A4" style={{...styles.page,paddingTop:50}}>
    {quote?.quote_additional_conditions.length ? (
        <View>
          <Text style={styles.heading}>Additional Conditions</Text>
          {quote?.quote_additional_conditions.map((line, index) => (
              <View key={index} style={{marginTop:3}}>
                <Text style={styles.subText} ><Text style={styles.subTextBold}>{line?.title}</Text> {line?.text}</Text>
              </View>    
          ))}
        </View>
      ) : null}


      <View wrap={false}>
        <Text style={{...styles.subTextMargin,marginLeft:0}}>
          Please confirm your acceptance of this quote, click the following link to see the Terms and Conditions:
        <a style={{marginLeft:5, textDecoration:"underline"}} href="https://static1.squarespace.com/static/62ba2d1190567e2eeab932ff/t/63c463ff7f97ec2fa37fa951/1673815039884/ScaffM8NZ_Limited_Terms_Conditions_of_trade.pdf">Link</a>
        </Text>
      </View>
      <View style={styles.footer} fixed>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </View>
    </Page>
  </Document>
  );
}


const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    // paddingTop: 10,
    paddingBottom: 40,
    paddingLeft: 50,
    paddingRight: 50,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: 150,
    alignItems: "left",
    fontFamily: "Open Sans",
    fontWeight: "semibold",
    fontSize: 9,
    color: "#1A8140",
    marginTop: 8,
  },
  headingContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headingContact: {
    textAlign: "right",
    marginTop: 20,
  },
  headingDisplay: {
    display: "flex",
    flexDirection: "row",
  },
  headingText: {
    fontFamily: "Open Sans",
    fontWeight: "semibold",
    fontSize: 7,
  },
  quoteText: {
    fontFamily: "Open Sans",
    fontWeight: "semibold",
    fontSize: 18,
  },
  subText: {
    fontFamily: "Open Sans",
    fontSize: 7,
    textAlign: "justify",
    // fontWeight: "semibold",
  },
  subTextMargin: {
    fontFamily: "Open Sans",
    marginLeft: 4,
    fontSize: 7,
    marginTop: 15,
    // fontWeight: "semibold",
  },
  subTextBold: {
    fontFamily: "Open Sans",
    marginLeft: 4,
    marginTop: 15,
    fontSize: 7,
    fontWeight: "bold",
  },
  subTextBoldRed: {
    fontFamily: "Open Sans",
    color: "red",
    marginLeft: 4,
    marginTop: 15,
    fontSize: 7,
    fontWeight: "bold",
    textAlign: "right"
  },
  logo: {
    objectFit: "contain",
    width: "20%",
    height: "auto",
    paddingTop: 20,
  },
  footer: {
    flexDirection: "row",
    position: "absolute",
    bottom: 7,
    right: 0,
  },
  pageTermsOfTrade: {
    objectFit: "contain",
    width: "105%",
    height: "auto",
    marginLeft: "-20"
  },
  footerLogo: {
    objectFit: "contain",
    width: "110%",
    // height: "auto",
    marginRight: 0,
  },

  footerText: {
    fontSize: 6,
    paddingLeft: 10,
  },

  pageNumber: {
    // position: "absolute",
    marginTop: 10,
    marginRight: 17,
    // paddingLeft: 70,
    fontSize: 8,
    // bottom: 30,
    // left: 0,
    // right: 0,
    textAlign: "center",
    color: "grey",
  },
});