import React, { useState, useEffect } from "react";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button as ButtonReact } from "primereact/button";
import { PencilAltIcon, XIcon } from "@heroicons/react/solid";
import { Calendar } from "primereact/calendar";
import DatePicker from "react-datepicker";
import {
  PageHeading,
  Button,
  Spinner,
  Button as CustomButton,
  XeroStatus,
  ConfirmationDialog,
} from "../../common";

import { Container, numberFormat } from "../../utils";

import "react-datepicker/dist/react-datepicker.css";
import "primeicons/primeicons.css";
import "primereact/resources/themes/fluent-light/theme.css";
import "primereact/resources/primereact.css";

import { WeeklyHireApi, JobsApi, InvoicesApi } from "../../api";
import { CreateEditEdInvoice } from "../../components/Jobs/CreateEditEdInvoice";
import { CreateEditWeeklyInvoice } from "../../components/WeeklyHire/EditHireInvoice";

moment.updateLocale("us", {
  workingWeekdays: [1, 2, 3, 4, 5],
});

export const InvoicesMain = () => {
  const hireQuery = WeeklyHireApi.useHiresPending();
  const edinvoicesQuery = JobsApi.useEDInvoices();
  const EditInvoicesMutate = InvoicesApi.useUpdateInvoice();
  const [openToEditWeeklyInvoice, setopenToEditWeeklyInvoice] = useState(false);
  const [openToEditEdInvoice, setopenToEditEdInvoice] = useState(false);
  const [filters, setFilters] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue] = useState("");
  const [invoicesSelected, setInvoicesSelected] = useState(null);
  const [invoices, setInvoices] = useState();
  const [invoiceIDToEdit, setInvoiceIDToEdit] = useState(null);

  const dt = React.useRef(null);

  useEffect(() => {
    if (!hireQuery.isLoading && !edinvoicesQuery.isLoading) {
      const weeklyInvoices = hireQuery?.data?.map((row) => ({
        ...row,
        InvoiceType: "Weekly Hire",
      }));

      const edInvoices = edinvoicesQuery?.data
        ?.filter((e) => e.status === "Pending")
        .map((row) => ({
          ...row,
          InvoiceType: "ED",
          total: row.EDTotal,
        }));

      const invoicesFormated = [...weeklyInvoices, ...edInvoices].sort((a, b) => {
        if (a.job_id < b.job_id) {
          return 1;
        }
        if (a.job_id > b.job_id) {
          return -1;
        }
        return 0;
      });
      setInvoices(invoicesFormated);
    }
  }, [hireQuery.data, edinvoicesQuery.data]);

  useEffect(() => {
    initFilters();
  }, []);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      InvoiceType: { value: null, matchMode: FilterMatchMode.EQUALS },
      completed_date: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      date_on_hire: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      on_hire: { value: null, matchMode: FilterMatchMode.EQUALS },
      // status: { value: "Active", matchMode: FilterMatchMode.EQUALS },
    });
    setGlobalFilterValue("");
  };

  const onGlobalFilterChange = (e) => {
    const { value } = e.target;
    const _filters = { ...filters };
    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const headerTemplate = (data) => (
    <td className="" colSpan="6">
      <span className="text-gray-900 font-bold">{`${data?.jobs?.job_num} - ${data?.jobs?.site}`}</span>
    </td>
  );

  const statusFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      options={["Yes", "No"]}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={(option) => option}
      placeholder="Select a Status"
      className="p-column-filter"
      showClear
    />
  );

  const renderHeader = () => (
    <>
      <div className="flex items-center">
        <ButtonReact
          type="button"
          icon="pi pi-filter-slash"
          label="Clear Filters"
          className="p-button-outlined"
          onClick={clearFilter}
        />
        <span className="p-input-icon-left ml-2">
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange}
            placeholder="Search Invoices"
          />
        </span>
      </div>
      <div className="mt-4">
        <ButtonReact
          type="Export"
          icon="pi pi-filter-slash"
          label="Export"
          className="p-button-outlined"
          onClick={() => exportCSV(false)}
        />
      </div>
    </>
  );

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const dateFilterTemplate = (options) => (
    <Calendar
      value={options.value}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      dateFormat="dd/mm/yy"
      placeholder="dd/mm/yyyy"
      mask="99/99/9999"
    />
  );

  const FilterTemplateInvType = (options) => (
    <Dropdown
      value={options.value}
      options={["ED", "Weekly Hire"]}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={(option) => option}
      placeholder="Select a Status"
      className="p-column-filter"
      showClear
    />
  );

  if (!invoices || !filters) {
    return <Spinner />;
  }

  return (
    <>
      <div>
        <Container>
          <PageHeading title="Invoices" isEditable={false} />
          <div className="mx-auto mt-8 px-2">
            <Button
              label="Success"
              icon="submit"
              onClick={() => console.log("Approving")}
              className="p-button-success ml-4"
            >
              Approve Invoices
            </Button>

            <br />

            <Button
              label="Success"
              icon="submit"
              onClick={() => console.log("EndOfMonth")}
              className="p-button-success ml-4"
            >
              End Of Month
            </Button>
            <br />

            {/*  <XeroStatus /> */}

            <DataTable
              scrollable={!openToEditWeeklyInvoice && !openToEditEdInvoice}
              ref={dt}
              value={invoices}
              dataKey="id"
              selectionMode="checkbox"
              groupRowsBy="job_id"
              rowGroupMode="subheader"
              header={renderHeader()}
              responsiveLayout="scroll"
              scrollHeight="600px"
              emptyMessage="No Invoices found."
              paginator
              paginatorPosition="top|bottom|both"
              showGridlines
              filters={filters}
              rows={100}
              rowsPerPageOptions={[25, 50, 100]}
              rowGroupHeaderTemplate={headerTemplate}
              selection={invoicesSelected}
              onSelectionChange={(e) => setInvoicesSelected(e.value)}
              autoLayout
            >
              <Column
                selectionMode="multiple"
                bodyClassName="p-text-center"
                style={{ maxWidth: "3.5rem" }}
              />
              <Column
                header="Invoice Type"
                field="InvoiceType"
                filter
                filterElement={FilterTemplateInvType}
                bodyClassName="p-text-center"
                style={{ width: "10px" }}
              />
              <Column
                header="Type"
                field="type"
                bodyClassName="p-text-center"
                style={{ width: "90px", whiteSpace: "break-spaces" }}
              />
              <Column
                header="Description"
                field="description"
                bodyClassName="p-text-center"
                style={{ width: "80em", whiteSpace: "break-spaces" }}
              />
              <Column
                header="On Hire"
                field="on_hire"
                filter
                filterElement={statusFilterTemplate}
                bodyClassName="p-text-center"
                style={{ maxWidth: "6.5rem" }}
              />
              <Column header="% Erect" field="completed" />
              <Column
                header="Erect Cost"
                field="erect"
                body={(row) => (row.erect ? <span>{numberFormat.format(row.erect)}</span> : "")}
                bodyClassName="p-text-center"
                style={{ width: "10px" }}
              />
              <Column
                header="Dismantle cost"
                field="dismantle"
                body={(row) =>
                  row.dismantle ? <span>{numberFormat.format(row.dismantle)}</span> : ""
                }
                bodyClassName="p-text-center"
                style={{ width: "10px" }}
              />
              <Column
                header="Date On Hire"
                field="date_on_hire"
                body={(data) => (
                  <span className="W-full">{dateBodyTemplate(data, "date_on_hire")}</span>
                )}
                dataType="date"
                filter
                filterElement={dateFilterTemplate}
                bodyClassName="p-text-center"
                style={{ width: "10px" }}
              />
              <Column
                header="Date Completed"
                field="completed_date"
                body={(data) => (
                  <span className="W-full">{dateBodyTemplate(data, "completed_date")}</span>
                )}
                dataType="date"
                filter
                filterElement={dateFilterTemplate}
                bodyClassName="p-text-center"
                style={{ width: "110px" }}
              />
              <Column
                header="Days on Hire"
                field="days_on_hire"
                body={(row) => {
                  if (row.date_on_hire) {
                    const days = calcDaysBetween(row.completed_date, row.date_on_hire);
                    return <span>{days}</span>;
                  }
                  if (row.completed_date) {
                    return row.days_on_hire;
                  }
                  return <></>;
                }}
                bodyClassName="p-text-center"
                style={{ maxWidth: "5rem" }}
              />
              <Column
                header="Weekly Hire Rate"
                field="weekly_hire_rate"
                body={(row) =>
                  row.weekly_hire_rate ? (
                    <span>{numberFormat.format(row.weekly_hire_rate)}</span>
                  ) : (
                    ""
                  )
                }
                bodyClassName="p-text-center"
                style={{ width: "110px" }}
              />
              <Column
                header="Total"
                field="total"
                body={(row) => {
                  if (row.ed_total) {
                    row.total = Number(row.ed_total);
                    return <span>{numberFormat.format(row.ed_total)}</span>;
                    // eslint-disable-next-line no-else-return
                  } else if (row.date_on_hire && row.weekly_hire_rate && row.completed) {
                    /**
                     * Days left on hire:
                     * If a completed date has been set, use that date as the days diff between date on hire and completed
                     * Otherwise, get remaining days till end of month.
                     */
                    const daysOnHire = calcDaysBetween(row.completed_date, row.date_on_hire);
                    const totalCalc = calculateInvoiceTotal(
                      daysOnHire,
                      row.weekly_hire_rate,
                      row.completed,
                    );
                    row.total = Number(totalCalc);
                    return <span>{numberFormat.format(totalCalc)}</span>;
                  }
                  return <></>;
                }}
                bodyClassName="p-text-center"
                style={{ width: "110px" }}
              />
              <Column
                header="Quote"
                headerStyle={{ textAlign: "center" }}
                field="Quote_Number"
                bodyStyle={{ textAlign: "center" }}
                bodyClassName="p-text-center"
                style={{ maxWidth: "4.5rem" }}
              />
              <Column
                header="Handover certificate"
                field="handover_url"
                body={(row) => (row.handover_url ? <a href={row.handover_url}>Link</a> : <></>)}
                bodyClassName="p-text-center"
                style={{ maxWidth: "5.5rem" }}
              />
              <Column
                header="Last Time updated"
                field="last_time_updated"
                body={(data) => moment(data).format("DD/MM/YYYY")}
                dataType="date"
                bodyClassName="p-text-center"
                style={{ width: "110px" }}
              />
              <Column
                header="Edit"
                bodyClassName="p-text-center"
                style={{ maxWidth: "3.5rem" }}
                body={(row) => (
                  <button
                    type="button"
                    onClick={() => {
                      if (row.InvoiceType === "Weekly Hire") {
                        setInvoiceIDToEdit(row.id);
                        setopenToEditWeeklyInvoice(true);
                      }
                      if (row.InvoiceType === "ED") {
                        setInvoiceIDToEdit(row.id);
                        setopenToEditEdInvoice(true);
                      }
                    }}
                  >
                    <PencilAltIcon className="h-4 w-4 text-gray-600" />
                  </button>
                )}
              />
              <Column
                header="Delete"
                style={{ maxWidth: "4.5rem" }}
                body={(row) => (
                  <ConfirmationDialog
                    isDone={EditInvoicesMutate.isSuccess}
                    icon="XIcon"
                    title={`Delete ${row.InvoiceType} Invoice`}
                    body="Are you sure you want to delete this item?"
                    triggerButton={
                      <button type="button">
                        <XIcon className="h-4 w-4 text-gray-600" />
                      </button>
                    }
                    confirmButton={
                      <CustomButton
                        isLoading={EditInvoicesMutate?.isLoading}
                        variant="primary"
                        onClick={async (e) => {
                          e.preventDefault();

                          try {
                            const data = await EditInvoicesMutate.mutateAsync({
                              newPayload: { status: "Deleted" },
                              id: row.id,
                              type: row.InvoiceType,
                            });
                          } catch (err) {
                            console.log("ERROR Deleting INVOICE", err);
                          }
                          // console.log("duplicatedInvoice", duplicatedInvoice);
                        }}
                      >
                        Delete Invoice
                      </CustomButton>
                    }
                  />
                )}
                bodyClassName="p-text-center"
              />
            </DataTable>
          </div>
        </Container>
      </div>
      {openToEditEdInvoice ? (
        <CreateEditEdInvoice
          edinvoiceID={invoiceIDToEdit}
          open={openToEditEdInvoice}
          setOpen={setopenToEditEdInvoice}
        />
      ) : (
        <br />
      )}
      {openToEditWeeklyInvoice ? (
        <CreateEditWeeklyInvoice
          invoiceID={invoiceIDToEdit}
          open={openToEditWeeklyInvoice}
          setOpen={setopenToEditWeeklyInvoice}
        />
      ) : (
        <br />
      )}
    </>
  );
};

const dateBodyTemplate = (rowData, field) => formatDate(rowData[field]);
const formatDate = (value) =>
  value
    ? value?.toLocaleDateString("en-NZ", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    : "";

function calcDaysBetween(completedDate, dateOnHire) {
  const daysLeftInMonth = completedDate ? moment(completedDate, "DD/MM/YYYY") : moment();
  const hiredDate = moment(dateOnHire, "DD/MM/YYYY");
  return daysLeftInMonth.businessDiff(hiredDate, "days");
}

function calculateInvoiceTotal(daysOnHire, hireRate, percentComplete) {
  /** ************
      Total Calculation:
      (Days_On_Hire * Daily_Fee) * (Percent_Complete / 100) >>>>> (13 * 100) * (50 / 100)
   
      Days_On_Hire = Days left in current month (From Date on Hire)
      Daily_Fee = “Weekly Hire Rate” / 5
      Percent_Complete = “% Complete”
   
    ************* */
  console.log("hireRate", hireRate);
  const dailyFee = (Number(hireRate) / 5).toFixed(2);
  const percent = Number(percentComplete);

  const totalCalc = daysOnHire * dailyFee * (percent / 100);
  console.log("CALC", daysOnHire, dailyFee, percent);
  return totalCalc.toFixed(2);
}
