import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import moment from "moment";
import supabase from "../../supabase";
import { checkJobStatus } from "./checkJobStatus";
import { useNotificationStore } from "../../../store/notifications";
import { updateTag } from "../../Tags/updateTag";

export async function updateTask({ payload, taskId }) {
  console.log("LOGS", payload, taskId)
  const { data, error } = await supabase.from("job_tasks").update(payload).match({ id: taskId });

  supabase.from("edinvoices").select("*").match({ task_id: taskId, status: "Pending" })
    .then((dataOfInvoices) => {
      if (dataOfInvoices.data.length === 0) {
        return
      }
      const last_time_updated = moment().toISOString();
      const erect_percent = Number((Number(payload?.percentage_complete) * 0.7).toFixed(2));
      const dismantle_percent = Number((Number(payload?.percentage_complete) * 0.3).toFixed(2));
      const invoiced = Number((Number(dataOfInvoices?.data[0]?.ed_total) * (payload?.percentage_complete / 100)).toFixed(2));

      const balance = invoiced - dataOfInvoices?.data[0]?.last_invoice;
      supabase.from("edinvoices").update({
        PO_Number: payload?.PO_Number,
        zone: payload?.zone, zone_label: payload?.zone_label, type: payload?.type, description: payload?.description,
        erect_percent, dismantle_percent, complete_percent: payload?.percentage_complete,
        last_time_updated, invoiced, balance
      }).match({ id: dataOfInvoices.data[0].id }).then((dataNewInvoice) => console.log(dataNewInvoice));

    });

  supabase.from("weekly_hire_invoices").update({
    type: payload?.type, zone_label: payload?.zone_label, zone: payload?.zone,
    description: payload?.description, completed: payload?.percentage_erect,
    on_hire: payload?.percentage_dismantle === 100 ? "No" : "Yes",
    completed_date: payload?.percentage_complete === 100 ? moment().format("DD/MM/YYYY") : null
  }).match({ task_id: taskId, status: "Pending" }).then((dataInvoice) => { console.log(dataInvoice) });


  supabase.from("scaffold_tags").select("*").match({ taskId }).then(newData => {
    if (!data[0]?.percentage_complete) return
    let newTagStatus = ""
    if (data[0]?.percentage_complete === 100) {
      newTagStatus = "Inactive"
    }
    if (data[0]?.percentage_complete !== 100 && data[0]?.percentage_complete !== undefined) {
      newTagStatus = "Active"
    }
    if (newData?.data[0]?.id) {
      try {
        const tag = { status: newTagStatus }
        updateTag({ tag, tagId: newData?.data[0]?.id })
      } catch (error) {
        console.log(error)
      }
    }

  });

  checkJobStatus(data[0]?.job_id);

  if (error) {
    throw new Error(error.messge);
  }

  try {
    await updateAppenateTask(data)
  } catch (err) {
    console.log("Error updating task")
  }

  return data;
}

export function useUpdateTask() {
  const { addNotification } = useNotificationStore();

  const queryClient = useQueryClient();
  let refreshType = "";

  return useMutation(
    ({ payload, taskId, type = "tasks" }) => {
      refreshType = type;
      return updateTask({ payload, taskId });
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries("variation_tasks");
        queryClient.refetchQueries("day_work_task");
        queryClient.refetchQueries("EDInvoices");
        addNotification({
          isSuccess: true,
          heading: "Success!",
          content: `Successfully updated task.`,
        });
      },
      onError: (err) => {
        addNotification({
          isSuccess: false,
          heading: "Failed update task",
          content: err?.message,
        });
      },
      mutationFn: updateTask,
    },
  );
}

async function updateAppenateTask(tasks) {
  const taskPayload = [];

  tasks.map((task) =>
    taskPayload.push([
      task.id,
      task.job_id || "",
      task.zone || "",
      task.zone_label || "",
      task.type || "",
      task.description || "",
      task.complete || "",
    ]),
  );
  return axios.post("https://verge-server.herokuapp.com/api/data-sync", {
    id: "08902523-ea3c-4a62-9162-af9c0155c568",
    data: taskPayload,
  });
}
